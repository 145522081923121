import axios from "axios";
import instance from "./instance/instance";
const host = process.env.REACT_APP_BACKEND_URL;

export const adminAddPlatform = async (token, datas) => {
  try {
    const { data } = await axios.post(`${host}/platform`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const adminGetSharedPlatforms = async (token) => {
  try {
    const { data } = await axios.get(`${host}/shared-platform`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const adminGetAllPlatforms = async (token) => {
  try {
    const { data } = await instance.get(`/platform`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response?.data?.message;
  }
};

export const adminEditPlatform = async (token, values) => {
  try {
    const { data } = await axios.put(`${host}/platform`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const editOrderHitPlatform = async (token, values, type) => {
  try {
    const { data } = await axios.patch(`${host}/platform/${type}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const findAdminPlatform = async (token) => {
  try {
    const { data } = await axios.get(`${host}/admin-platform`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
