import { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { adminGetAllPlatforms } from "../services/platform";

const LiveSocketContext = createContext(null);

export const useLivePriceSocket = () => {
  const state = useContext(LiveSocketContext);
  if (!state) throw new Error(`state is undefined`);
  return state;
};

function SocketProvider({ children }) {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state?.admin);
  const platform = useSelector((state) => state?.platform);
  const [gold, setGoldSocket] = useState({
    cur: {
      Balance: 0,
      Equity: 0,
      Leverage: 0,
      Margin: 0,
      Profit: 0,
      ask: 0,
      bid: 0,
      freeMargin: 0,
      highPrice: 0,
      isMarketOpen: 0,
      lowPrice: 0,
      marginLevel: 0,
    },
    pre: {
      Balance: 0,
      Equity: 0,
      Leverage: 0,
      Margin: 0,
      Profit: 0,
      ask: 0,
      bid: 0,
      freeMargin: 0,
      highPrice: 0,
      lowPrice: 0,
      marginLevel: 0,
    },
  });
  const [render, setRender] = useState(true);
  const [socket2, setSocket2] = useState(false);

  const handleFindPlatforms = async () => {
    const resp = await adminGetAllPlatforms(admin?.token);
    if (resp?.length) {
      dispatch({ type: "PLATFORM", payload: resp[0] });
      Cookies.set("platform", JSON.stringify(resp[0]));
    }
  };

  useEffect(() => {
    if (!platform && admin) {
      handleFindPlatforms();
    } else if (render == false && platform?.render) {
      socket2.disconnect();
      setRender(true);
    }
  }, [platform]);

  useEffect(() => {
    if (render && admin && platform) {
      setRender(false);
      let socket = io(platform?.socketUrl, {
        reconnection: true, // Enable automatic reconnection
        reconnectionAttempts: Infinity, // Try to reconnect infinitely
      });

      socket.on("connect", () => {
        socket.emit("join_room", { roomId: platform?.accNumber });
      });
      socket.on("gold", (data) => {
        setGoldSocket((e) => {
          return { cur: data, pre: e.cur };
        });
      });
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      setSocket2(socket);
    }
  }, [render, platform]);

  return (
    <LiveSocketContext.Provider value={{ gold }}>
      {children}
    </LiveSocketContext.Provider>
  );
}

export default SocketProvider;
