import { combineReducers } from "redux";
import { adminReducer } from "../reducers/adminReducer";
import { platformReducer } from "../reducers/platformReducer";

const rootReducer = combineReducers({
  admin: adminReducer,
  platform: platformReducer,
});

export default rootReducer;
