import Cookies from "js-cookie";
import axios from "axios";

const instance = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL });

instance.interceptors.request.use(
  (config) => {
    const admin = JSON.parse(Cookies.get("admin"));
    if (admin) config.headers["Authorization"] = `Bearer ${admin?.token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
