import React, { useCallback, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

import { useSelector } from "react-redux";
import { createContext } from "react";
const SocketContext = createContext(null);

export const useSocket = () => {
  const state = useContext(SocketContext);
  if (!state) throw new Error(`state is undefined`);
  return state;
};

export const LiveEmitSocketProvider = ({ children }) => {
  const admin = useSelector((state) => state.admin) ?? "";
  const [socket, setSocket] = useState();
  const [orderRender, setOrderRender] = useState(true);
  const [transactionRender, setTransactionRender] = useState(true);

  useEffect(() => {
    const connectSocket = async () => {
      try {
        const _socket = io(process.env.REACT_APP_SOCKET_URL);
        _socket.emit("room", admin._id);
        _socket.on("neworder", (data) => {
          setOrderRender((e) => !e);
        });
        _socket.on("newTransaction", () => {
          setOrderRender((e) => !e);
        });
        _socket.on("physicalOrder", () => {
          setOrderRender((e) => !e);
        });

        setSocket(_socket);
      } catch (error) {
        console.error("Error connecting to socket:", error);
      }
    };
    if (admin?._id) connectSocket();
    // Clean up socket connection on component unmount
    return () => {
      if (socket) {
        socket.disconnect();
        setSocket(undefined);
      }
    };
  }, [admin]);
  return (
    <SocketContext.Provider value={{ orderRender, transactionRender }}>
      {children}
    </SocketContext.Provider>
  );
};
