import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./store/rootReducer";
import { LiveEmitSocketProvider } from "./socket/LiveEmitSocketProvider";
import SocketProvider from "./socket/SocketProvider";
const store = createStore(rootReducer, composeWithDevTools());

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Roboto",
      fontWeight: 300,
      textTransform: "none",
      fontSize: "15px",
      color: "#FFFFFF",
      letterSpacing: 0.6,
    },
    h1: {
      fontSize: "20px",
    },
    h2: {
      fontSize: "18px",
    },
    h3: {
      fontSize: "16px",
    },
    h4: {
      fontSize: "14px",
    },
    h5: {
      fontSize: "12px",
    },
    h6: {
      fontSize: "10px",
    },
  },
  palette: {
    mode: "dark",
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter forceRefresh={true}>
      <Provider store={store}>
        <SocketProvider>
          <LiveEmitSocketProvider>
            <App />
          </LiveEmitSocketProvider>
        </SocketProvider>
      </Provider>
    </BrowserRouter>
  </ThemeProvider>
);
