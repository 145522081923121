import "./App.css";
import { Toaster } from "react-hot-toast";
import { useMediaQuery } from "@mui/material";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
const AppRoutes = React.lazy(() => import("./AppRoutes"));
const NoInternet = React.lazy(() => import("./components/utils/NoInternet"));

function App() {
  const isLargeScreen = useMediaQuery("(min-width: 1000px)");
  const [status, setStatus] = useState(true);

  useEffect(() => {
    function changeStatus() {
      setStatus(navigator.onLine);
    }
    window.addEventListener("online", changeStatus);
    window.addEventListener("offline", changeStatus);
    return () => {
      window.removeEventListener("online", changeStatus);
      window.removeEventListener("offline", changeStatus);
    };
  }, []);
  return (
    <>
      {status ? (
        <Box className="App" sx={{ display: "flex" }}>
          {isLargeScreen ? (
            <>
              <Toaster
                position="top-center"
                reverseOrder={false}
                toastOptions={{
                  className: "",
                  style: {
                    padding: "16px",
                    minWidth: "300px",
                  },
                  duration: 1500,
                }}
              />
              <AppRoutes />
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
              }}
            >
              <Typography>Mobile preview not available</Typography>
            </Box>
          )}
        </Box>
      ) : (
        <NoInternet />
      )}
    </>
  );
}

export default App;
