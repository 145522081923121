import Cookies from "js-cookie";

export function platformReducer(
  state = Cookies.get("platform") ? JSON.parse(Cookies.get("platform")) : null,
  action
) {
  switch (action.type) {
    case "PLATFORM":
      return action.payload;
    case "REMOVEPLATFORM":
      return null;
    default:
      return state;
  }
}
